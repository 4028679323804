import React from "react"
import FakeMap from "src/assets/images/Map.png"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function BusMap({ latitude, longitude }: any) {
  return (
    <div className="h-full rounded-3 border bg-white p-4">
      <div className="mb-4 flex items-center justify-between gap-4">
        <h2 className="w-full text-18 font-semibold">Bus map</h2>
        <div className="flex gap-3">
          <div className="truncate text-14 font-semibold">Latitude: {latitude}</div>
          <div className="truncate text-14 font-semibold">Longitude: {longitude}</div>
        </div>
      </div>

      <img src={FakeMap} alt="Map" />
    </div>
  )
}

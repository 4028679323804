import { Dialog } from "primereact/dialog"
import React from "react"
import "./index.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"

interface MediaModalProps {
  handleClose: () => void
  listImage: string[]
}

export interface MediaType {
  id: string
  isImage: boolean
  type: string
}

export default function MediaModal(props: MediaModalProps) {
  const { handleClose, listImage } = props
  return (
    <Dialog
      id="video_full_screen"
      visible={true}
      style={{ width: "650px",padding:0 }}
      onHide={handleClose}
    >
      <Swiper
        navigation={true}
        spaceBetween={38}
        modules={[Navigation]}
        slidesPerView={1}
        initialSlide={0}
      >
        {listImage?.map((image) => (
          <SwiperSlide key={image}>
            <div className="h-full w-full cursor-pointer focus:shadow-none p-1">
              <img
                src={`${process.env.REACT_APP_API_URL}/bus-monitoring/bus-monitorings/image/${image}`}
                alt="commentImage"
                className="h-full w-full rounded-4 object-contain "
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Dialog>
  )
}

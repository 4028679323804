import React from "react"
import { Chart } from "primereact/chart"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function BusChart({chartData}:{chartData?:any}) {
  const legends = [
    {
      title: "On bus",
      color: "#FDB022",
    },
    {
      title: "Off bus",
      color: "#2E90FA",
    },
    {
      title: "Total",
      color: "#D0D5DD",
    },
  ]
  const chartDataDefault = {
    labels: ["12:00", "13:00", "14:00", "1500", "16:00", "17:00"],
    datasets: [
      {
        label: legends[0].title,
        fill: false,
        borderColor: "#FDB022",
        data: [65, 59, 80, 81, 56, 55, 10],
      },
      {
        label: legends[1].title,
        fill: false,
        borderColor: "#2E90FA",
        data: [28, 48, 40, 19, 86, 27, 90],
      },
      {
        label: legends[2].title,
        fill: false,
        borderColor: "#D0D5DD",
        data: [27, 28, 19, 48, 40, 86, 90],
      },
    ],
  }

  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
      },

    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",

        grid: {
          drawBorder: true,
          borderDash: [5, 5],
        },
        ticks: {
          stepSize: 20,
          min: 0, max: 100,
        },
      },

    },
  }
  return (
    <div className="border rounded-3 bg-white p-4 h-full">
      <h2 className="mb-4 text-18 font-semibold">Customers</h2>
      <Chart height="342px" type="line" data={chartData||chartDataDefault} options={chartOptions} />
      <div className="flex gap-4 justify-center mt-1">
        {legends.map(item =>
          <span key={item.title} className="flex gap-1 items-center">
            <div style={{ backgroundColor: item.color }} className="w-3 h-[2px] rounded-1"></div>
            <div className="text-14">{item.title}</div>
          </span>
        )}
      </div>
    </div>
  )
}
/* eslint-disable indent */
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import bgImage from "src/assets/images/BG.png"
import BusList from "src/components/bus-lists"
import BusSummary from "src/components/bus-summary"
import { Product, Summary, AlertHistoryAPI } from "./type"
import SystemHistory from "src/components/system-history"
import { BusWebsocketInstance } from "src/utils/bus-socket-instance"
import { getListBus, getSystemHistory } from "src/services/bus-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { BusDetail } from "../bus-details/type"
import { handlePromiseAll } from "src/utils/common"
import { History } from "src/components/system-history"

export default function Home() {
  const storeValue = useSelector<RootState>((state) => state.counter.value)
  const [valueSearch, setValueSearch] = useState<string>("")
  const [listBus, setListBus] = useState<Product[]>([])
  const [dataHistorys, setdataHistorys] = useState<History[]>([])
  const [dataHistoryAPI, setDataHistoryAPI] = useState<AlertHistoryAPI[]>([])
  const [afterFilter, setAfterFilter] = useState<Product[]>([])
  const [summary, setSummary] = useState<Summary>({
    total: 0,
    active: 0,
    inactive: 0,
  })

  const getBus = async () => {
    try {
      const data = await getListBus()
      if (data.data) {
        const listBus: Product[] = data.data.map((item: BusDetail) => {
          return {
            id: item.bus_id,
            status: item.status,
            upTime: item.timeStamp.slice(11, 19),
            veNumber: item.vehicle_number,
          }
        })

        const total = listBus?.length
        const active = listBus.filter((item: Product) => item.status === "Active")?.length
        const inactive = total - active
        setSummary({ total, active, inactive })
        setListBus(listBus)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getHistory = async () => {
    try {
      const dataHistory = await getSystemHistory()
      if (dataHistory?.data?.data) {
        setDataHistoryAPI(dataHistory?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis: any[] = []
    apis.push(getBus())
    apis.push(getHistory())
    handlePromiseAll(apis)
  }, [storeValue])

  useEffect(() => {
    if (listBus && dataHistoryAPI) {
      const data: History[] = dataHistoryAPI.map((item: AlertHistoryAPI) => {
        const busDetail: Product = listBus?.filter((bus) => bus.id === item.bus_id)[0]
        return {
          busName: busDetail?.veNumber,
          busStatus: busDetail?.status,
          id: item.alert.alert_id,
          note: item.alert.alertContent,
          status:
            item.alert.alertLevel === "WARNING"
              ? "WARNING"
              : item.alert.alertLevel === "ALERT"
              ? "ALERT"
              : "INFO",
          time: item.alert.timeStamp,
          images: [item.alert.image],
        }
      })
      setdataHistorys(data)
    }
  }, [dataHistoryAPI, listBus])

  useEffect(() => {
    if (valueSearch) {
      const results = listBus.filter(
        (bus) => bus.id.includes(valueSearch) || bus.veNumber.includes(valueSearch)
      )

      setAfterFilter(results)
    }
    if (!valueSearch) {
      setAfterFilter(listBus)
    }
  }, [valueSearch])

  useEffect(() => {
    setAfterFilter(listBus)
  }, [listBus])

  useEffect(() => {
    BusWebsocketInstance?._connect()
    return () => {
      BusWebsocketInstance._disconnect()
    }
  }, [])

  return (
    <div
      className="relative h-full min-h-[calc(100vh-64px)] w-full bg-no-repeat"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex h-full w-full gap-4 p-4">
        <div className="flex h-full w-1/2 gap-4 ">
          <div className="flex h-full w-1/2 flex-col gap-4">
            <BusSummary summary={summary} />
            <div className="flex h-full w-full flex-col rounded-3 bg-white">
              <div className="flex w-full flex-col items-start justify-start px-2 py-4">
                <div className="text-18 font-semibold leading-7 text-gray-900">Bus list</div>
                <div>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search"
                      className="h-[40px] w-full"
                      value={valueSearch}
                      onChange={(e) => setValueSearch(e.target.value)}
                    />
                  </span>
                </div>
              </div>
              <div className="w-full px-2 pb-2">
                <BusList data={afterFilter} />
              </div>
            </div>
          </div>
          <div className="h-full w-1/2 rounded-3 bg-white">
            <SystemHistory history={dataHistorys} />
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import "./index.scss"
import { Product, TableProps } from "src/pages/home/type"
import { useNavigate } from "react-router"
import { STATUS } from "src/constants/fake-data"

export default function BusList({ data }: TableProps) {
  const [busList, setBusList] = useState<Product[]>([])
  const navigate = useNavigate()
  useEffect(() => {
    setBusList(data)
  }, [data])

  const statusBodyTemplate = (bus: Product) => {
    return (
      <div
        className={`flex h-[20px] w-[53px] items-center justify-center rounded-5 px-[4px] py-[2px] text-12 font-medium leading-4 ${
          STATUS.ACTIVE === bus.status.toUpperCase()
            ? "bg-blue-50 text-blue-600"
            : "bg-gray-100 text-gray-700"
        }`}
      >
        <div>{bus.status}</div>
      </div>
    )
  }

  const idBodyTemplate = (bus: Product) => {
    const handleGoToDetails = () => {
      navigate(`/bus-details/${bus.id}`)
    }
    return (
      <div
        onClick={handleGoToDetails}
        className="line-clamp-1 w-[60px] cursor-pointer truncate text-14 font-medium text-blue-600 hover:underline"
      >
        {bus.id}
      </div>
    )
  }

  return (
    <div className="max-h-screen w-full rounded-3 border-l border-r border-t">
      <DataTable value={busList} size="small" className="rounded-3" scrollable scrollHeight="485px">
        <Column field="id" header="Bus ID" className="h-[56px]" body={idBodyTemplate}></Column>
        <Column
          field="veNumber"
          header="Vehicle’s number"
          className="cursor-pointer truncate text-14 font-medium text-gray-700"
        ></Column>
        <Column
          header="Status"
          className="cursor-pointer truncate text-14 font-medium text-gray-700"
          body={statusBodyTemplate}
        ></Column>
        <Column
          field="upTime"
          header="Uptime"
          className="cursor-pointer truncate text-14 font-medium text-gray-700"
        ></Column>
      </DataTable>
    </div>
  )
}

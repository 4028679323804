import React, { useRef } from "react"
import "./App.scss"
import MainLayout from "./components/layout/main-layout"
import { Navigate, useRoutes } from "react-router-dom"
import { Toast } from "primereact/toast"
import { ToastContext } from "./app/toast-context"
import { ROUTERS } from "./constants/router"
import Home from "./pages/home"
import BusDetails from "./pages/bus-details"

function App() {
  const routes = useRoutes([
    {
      path: ROUTERS.HOME,
      element: <Home />,
    },
    { path: ROUTERS.BUS_DETAILS, element: <BusDetails /> },
    { path: "*", element: <Navigate to={ROUTERS.PAGE_NOT_FOUND} /> },
  ])

  const toast = useRef<Toast>(null)
  return (
    <ToastContext.Provider value={toast}>
      <MainLayout onSearch={(keyword) => console.log("searching", keyword)}>
        {routes}
      </MainLayout>
      <Toast ref={toast} />
    </ToastContext.Provider>
  )
}

export default App

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import I18NextHttpBackend from "i18next-http-backend"
import translationEN from "./en/translation.json"
import translationVI from "./vi/translation.json"
const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
}
i18n
  .use(I18NextHttpBackend)

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("lang") || "en",
    debug: false,
    fallbackLng: "en",
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
  })

const updateTitle = () => (window.document.title = "Bus monitoring system")
updateTitle()
i18n.on("languageChanged", () => {
  updateTitle()
})
export default i18n

/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import { BreadCrumb } from "primereact/breadcrumb"
import BusInfo from "src/components/bus-details/bus-info"
import BusChart from "src/components/bus-details/bus-chart"
import BusMap from "src/components/bus-details/bus-map"
import SystemHistory, { History } from "src/components/system-history"
import "./styles.scss"
import { getBusDetailById } from "src/services/bus-service"
import { useParams } from "react-router-dom"
import { BusDetail } from "./type"
import { BusWebsocketInstance } from "src/utils/bus-socket-instance"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"

const initBusDetail: BusDetail = {
  alerts: [],
  bus_id: "",
  depart_time: "",
  driver_name: "",
  engine_number: "",
  guestStatuses: [],
  latitude: 0,
  longitude: 0,
  model: "",
  runtime: "",
  speed: 0,
  status: "",
  timeStamp: "",
  total: 0,
  vehicle_number: "",
  odo: 0,
}
export default function BusDetails() {
  const param = useParams()
  const items = [{ label: "Bus details", icon: "" }]
  const home = { icon: "pi pi-home", url: "/", label: "Dashboard" }
  const [dataDetail, setDataDetail] = useState<BusDetail>(initBusDetail)
  const [chartData, setchartData] = useState({})
  const [dataHistorys, setdataHistorys] = useState<History[]>([])
  const storeValue = useSelector<RootState>((state) => state.counter.value)
  const getDetailBus = async () => {
    try {
      if (param?.id) {
        const data = await getBusDetailById(param.id)
        if (data?.data) {
          setDataDetail(data?.data?.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDetailBus()
  }, [param?.id])

  useEffect(() => {
    if (storeValue !== 0) getDetailBus()
  }, [storeValue])

  useEffect(() => {
    BusWebsocketInstance?._connect()
    return () => {
      BusWebsocketInstance._disconnect()
    }
  }, [])

  useEffect(() => {
    const dataOut: number[] = []
    const dataOn: number[] = []
    const total: number[] = []

    const label = dataDetail?.guestStatuses?.map((item) => {
      dataOut.push(item.outBus)
      dataOn.push(item.onBus)
      total.push(item.total)
      const str = item.timeStamp
      return str?.slice(11, 16)
    })

    const chartDataDefault = {
      labels: label,
      datasets: [
        {
          label: "On bus",
          fill: false,
          borderColor: "#FDB022",
          data: dataOn,
        },
        {
          label: "Out bus",
          fill: false,
          borderColor: "#2E90FA",
          data: dataOut,
        },
        {
          label: "Total",
          fill: false,
          borderColor: "#D0D5DD",
          data: total,
        },
      ],
    }

    setchartData(chartDataDefault)
  }, [dataDetail?.guestStatuses])

  useEffect(() => {
    const dataHistory: History[] = dataDetail.alerts.map((item) => {
      return {
        busName: dataDetail.vehicle_number,
        busStatus: dataDetail.status,
        id: item.alert_id,
        images: [item.image],
        note: item.alertContent,
        status: item.alertLevel,
        time: item.timeStamp,
      }
    })

    dataHistory.sort((a: History, b: History) => {
      const dateA = new Date(a.time.replace(" ", "T")) // Chuyển đổi " " thành "T" để tạo đối tượng Date
      const dateB = new Date(b.time.replace(" ", "T"))
      return dateB.getTime() - dateA.getTime()
    })

    setdataHistorys(dataHistory)
  }, [dataDetail.alerts])

  return (
    <div>
      <BreadCrumb model={items} home={home} />

      <div className="flex gap-4 px-4 pb-4">
        <div className="flex flex-[2] flex-col gap-4">
          <div className="flex min-h-[462px] gap-4">
            <div className="h-full flex-1">
              <BusInfo busDetail={dataDetail} />
            </div>
            <div className="h-full flex-1">
              <BusChart chartData={chartData} />
            </div>
          </div>
          <div>
            <BusMap latitude={dataDetail.latitude} longitude={dataDetail.longitude} />
          </div>
        </div>
        <div className="flex-1">
          <SystemHistory history={dataHistorys} />
        </div>
      </div>
    </div>
  )
}

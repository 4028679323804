import React from "react"
import { Badge } from "primereact/badge"
import { Avatar } from "primereact/avatar"
import Logo from "src/assets/images/TMA_logo.svg"


export default function Header() {
  return (
    <header className="px-4 py-2 flex justify-between items-center bg-white">
      <div className="flex items-center gap-3">
        <img src={Logo} alt="TMA Solutions" />
        <div className="h-4 border"></div>
        <span className="text-20 font-semibold text-blue-600 ">Bus monitoring system</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-40px h-40px flex items-center justify-center">
          <i className="pi pi-search text-20"></i>
        </div>
        <div className="w-40px h-40px flex items-center justify-center">
          <i className="pi pi-bell p-overlay-badge text-20" >
            <Badge severity="danger"></Badge>
          </i>
        </div>
        <div className="flex items-center gap-4px rounded-[100px] bg-gray-100 p-4px pl-10px">
          <span className="text-14">John Eragon</span>
          <Avatar style={{ width: "32px", height: "32px" }} image="https://lh3.googleusercontent.com/ogw/AF2bZyicCInwck83mEbkSSGtdER-XpDOd7hhSMRwbI7LI1s=s32-c-mo" size="xlarge" shape="circle" />
        </div>
      </div>
    </header>
  )
}
import http from "src/config/interceptors"

export const getListBus = () => {
  return http.get("/bus-monitoring/bus-monitorings/bus-monitoring")
}

export const getBusDetailById = (bus_id: string) => {
  return http.get(`/bus-monitoring/bus-monitorings/bus/${bus_id}`)
}

export const getSystemHistory = () => {
  return http.get("/bus-monitoring/bus-monitorings/api/alerts")
}

import React, { useState } from "react"
import WarningIcon from "src/assets/icons/warning.svg"
import CriticalIcon from "src/assets/icons/critical.svg"
import InfoIcon from "src/assets/icons/info.svg"
import MediaModal from "../modal/media-modal"

export type StatusType = "WARNING" | "ALERT" | "INFO"

export interface History {
  id: string
  status: StatusType
  busName: string
  busStatus: string
  time: string
  note: string
  images: string[]
}

interface SystemHistoryProps {
  history: History[]
}

export default function SystemHistory({ history }: SystemHistoryProps) {
  const [isShowMedia, setIsShowMedia] = useState<boolean>(false)
  const [listImage, setListImage] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickImage = (item: string[]) => {
    setListImage(item)
    setIsShowMedia(true)
  }
  const handleClose = () => {
    setIsShowMedia(false)
  }

  const getIcon = (status: StatusType) => {
    let result = ""
    if (status === "WARNING") {
      result = WarningIcon
    } else if (status === "ALERT") {
      result = CriticalIcon
    } else {
      result = InfoIcon
    }

    return result
  }

  return (
    <div className="h-full rounded-3 border bg-white p-4">
      <h2 className="mb-4 text-18 font-semibold">System history</h2>
      <div className="rounded flex h-5 items-center justify-center bg-gray-100 text-12 font-semibold text-gray-500">
        {history?.[0]?.time ? history?.[0]?.time.slice(0, 10) : "N/N"}
      </div>

      <div className="8 mt-[20px] flex max-h-[800px] flex-col gap-2 overflow-x-auto">
        {history.map((item) => (
          <div
            key={item.id}
            className="relative mx-3 min-h-[170px]"
            onClick={() => handleClickImage(item.images)}
          >
            <div className="absolute bottom-0 h-[calc(100%_-_40px)] border bg-gray-100"></div>
            <div className="flex flex-col gap-1 px-40px">
              <div className="text-14 font-medium">
                <span className="text-blue-500">{item.busName} </span>
                <span>- {item.busStatus} </span>
              </div>
              <div className="text-12 text-gray-500">{item.time}</div>
              <div className="text-14">{item.note} </div>
              <div className="flex flex-wrap gap-2">
                {item.images.map((item, index) => (
                  <img
                    className="h-80px w-80px rounded-3 object-cover object-center"
                    key={index}
                    src={`${process.env.REACT_APP_API_URL}/bus-monitoring/bus-monitorings/image/${item}`}
                    alt={item}
                  />
                ))}
              </div>
            </div>
            <img className="absolute -left-3 top-0" src={getIcon(item.status)} alt="Warning" />
          </div>
        ))}
      </div>
      {isShowMedia && listImage ? (
        <MediaModal handleClose={handleClose} listImage={listImage}></MediaModal>
      ) : null}
    </div>
  )
}

export const DATABUS = [
  {
    id: "Bus_0561",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
  {
    id: "Bus_0562",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
  {
    id: "Bus_0563",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-33224",
  },
  {
    id: "Bus_0564",
    status: "Inactive",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
  {
    id: "Bus_0565",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-11111",
  },
  {
    id: "Bus_0566",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
  {
    id: "Bus_0567",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-22222",
  },
  {
    id: "Bus_0568",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-55555",
  },
  {
    id: "Bus_0569",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
  {
    id: "Bus_0561",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
  {
    id: "Bus_0561",
    status: "Active",
    upTime: "13:00",
    veNumber: "50H-56789",
  },
]

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
}

import React from "react"
import "./main-layout.scss"
import type { MainLayoutProps } from "./main-layout-props"
import Header from "../header"
export type { MainLayoutProps }

export default function MainLayout(props: MainLayoutProps) {
  return (
    <div className="mx-auto flex flex-col bg-gray-100" id="content-body">
      <Header />
      <main className="flex w-full flex-col justify-center gap-1 bg-gray-50">
        <div className="h-full w-full">{props.children}</div>
      </main>
    </div>
  )
}

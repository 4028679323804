import React from "react"
import { Divider } from "primereact/divider"
import FakeBusImage from "src/assets/images/BUS-HCM19.png"
import ExpandIcon from "src/assets/icons/expand.svg"
import { BusDetail } from "src/pages/bus-details/type"
import { RenesasLinkConfig } from "src/constants/constants/data"
export default function BusInfo({ busDetail }: { busDetail: BusDetail }) {
  const detailsInfo = [
    {
      title: "Model",
      value: busDetail.model,
    },
    {
      title: "ODO",
      value: `${busDetail.odo} km`,
    },
    {
      title: "Marker",
      value: "45349242",
    },
    {
      title: "Speed",
      value: `${busDetail.speed} km/hr `,
    },
    {
      title: "Engine number",
      value: busDetail.engine_number,
    },
    {
      title: "Runtime",
      value: busDetail.runtime,
    },
  ]

  return (
    <div className="rounded-3 border bg-white p-4 min-w-[500px]">
      <img src={FakeBusImage} alt="Bus Image" />

      <div className="mt-3">
        <div className="mb-2 flex h-[28px] items-center justify-between">
          <div className="flex flex-1 items-center justify-between gap-1">
            <span className="flex-2 line-clamp-1 w-full text-18 font-semibold">
              BUS - {busDetail.vehicle_number}
            </span>
            {busDetail.status === "Active" ? (
              <span className="text-sm inline-block flex-1 rounded-[100px] bg-green-50 px-1 py-[2px] text-14 font-medium text-green-600">
                Online
              </span>
            ) : (
              <span className="text-sm inline-block flex-1 rounded-[100px] bg-gray-200 px-1 py-[2px] text-14 font-medium text-green-600">
                Offline
              </span>
            )}
          </div>
          <div className=" hidden flex-1 items-center justify-end gap-4px">
            <span className="text-14 font-medium text-blue-600">Video stream</span>
            <img src={ExpandIcon} alt="Expand" />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-4 gap-y-1">
          <div className="flex items-center justify-between">
            <span className="text-14 text-gray-500">Vehicle number:</span>
            <span className="text-14 font-medium">{busDetail.vehicle_number}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-14 text-gray-500">Device:</span>
            <span className="text-14 font-medium text-blue-600 hover:underline">
              <a href={RenesasLinkConfig}>Renesas - RZ/V2L 3.1.21</a>
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-14 text-gray-500">Driver’s name:</span>
            <span className="text-14 font-medium">{busDetail.driver_name}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-14 text-gray-500">Depart time:</span>
            <span className="text-14 font-medium">{busDetail.depart_time}</span>
          </div>
        </div>

        <div className="my-3">
          <Divider type="dashed" />
        </div>

        <div className="grid grid-cols-2 gap-x-4 gap-y-1">
          {detailsInfo.map((item) => (
            <div key={item.title} className="flex items-center justify-between">
              <span className="text-14 text-gray-500">{item.title}:</span>
              <span className="text-14 font-medium">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { ProgressBar } from "primereact/progressbar"
import { Summary } from "src/pages/home/type"
export default function BusSummary({ summary }: { summary: Summary }) {
  const { total, active, inactive } = summary

  const handleCalculate = (total: number, item: number) => {
    const results = (item * 100) / total
    return results
  }
  return (
    <div className="flex h-fit w-full flex-col gap-2 rounded-3 bg-white p-4">
      <div className="flex w-full flex-col gap-[4px]">
        <div className="text-12 font-medium leading-4 text-gray-500">
          TOTAL BUSES
        </div>
        <div className="w-full text-[30px] font-semibold leading-10 text-gray-900">
          {total}
        </div>
      </div>
      <div className="flex w-full flex-col gap-2">
        <div className="flex flex-col">
          <div className="flex w-full items-center justify-between">
            <div className="w-full text-14 font-normal leading-5 text-gray-700">
              Active buses
            </div>
            <div className="flex w-1/2 items-center justify-end text-14 font-semibold leading-5 text-gray-900">
              {active}
            </div>
          </div>
          <div className="w-full">
            <ProgressBar
              className="h-1"
              value={handleCalculate(total, active)}
              color="#16B364"
              showValue={false}
            ></ProgressBar>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex w-full items-center justify-between">
            <div className="w-full text-14 font-normal leading-5 text-gray-700">
              Inactive buses
            </div>
            <div className="flex w-1/2 items-center justify-end text-14 font-semibold leading-5 text-gray-900">
              {inactive}
            </div>
          </div>
          <div className="w-full">
            <ProgressBar
              className="h-1"
              value={handleCalculate(total, inactive)}
              color="#F04438"
              showValue={false}
            ></ProgressBar>
          </div>
        </div>
      </div>
    </div>
  )
}
